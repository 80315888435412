import { PlusCircleOutlined } from '@ant-design/icons';
import { GET_FOUNDER_DASHBOARDS } from '@pages/content/pulse/api/get-founder-dashboards/get-founder-dashboards.action';
import { DropdownList } from '@parts/dropdown-list/dropdown-list';
import message from '@parts/message/message';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import useConfirmModal from '@utils/hooks/use-confirm-modal/use-confirm-modal';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Menu } from 'antd';
import { deleteDashboardAction } from '../../../api/delete-dashboard/delete-dashboard.actions';
import { VisibilityMode } from '../../../api/get-founder-dashboard/get-founder-dashboard.action';
import { Visibility } from '../visibility/visibility';
import { ShareDashboard } from './parts/share-dashboard/share-dashboards';
import S from './submenu.styles';
import { useNavigate } from 'react-router-dom';

export const Submenu = ({
  dashboardId,
  onChangeNameClick,
  visibility,
  displayAddForm,
  hasCloseConnection,
}: {
  dashboardId: string;
  onChangeNameClick: () => void;
  visibility: VisibilityMode;
  displayAddForm: () => void;
  hasCloseConnection?: boolean;
}) => {
  const [
    changeNameLabel,
    deleteLabel,
    deleteDashboardContentPrivateLabel,
    deleteDashboardContentConnectionsLabel,
    deleteDashboardContentCommunityLabel,
    deleteDashboardContentQuestionLabel,
    deleteDashboardTitleLabel,
    successDeleteLabel,
    addMetricLabel,
  ] = useTranslation([
    'pulse.submenu.changeName',
    'global.delete',
    'founder.pulseDashboard.deleteModal.private.content',
    'founder.pulseDashboard.deleteModal.connections.content',
    'founder.pulseDashboard.deleteModal.community.content',
    'founder.pulseDashboard.deleteModal.question.content',
    'founder.pulseDashboard.deleteModal.title',
    'founder.pulseDashboard.delete.success',
    'pulse.dashboard.addMetric',
  ]);

  const getContentOfDeleteModal = () => {
    const labels = {
      [VisibilityMode.Community]: deleteDashboardContentCommunityLabel,
      [VisibilityMode.Connections]: deleteDashboardContentConnectionsLabel,
      [VisibilityMode.Private]: deleteDashboardContentPrivateLabel,
      [VisibilityMode.CloseConnections]: deleteDashboardContentConnectionsLabel,
    };

    return (
      <>
        <S.Question>{deleteDashboardContentQuestionLabel}</S.Question>
        {labels[visibility]}
      </>
    );
  };
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutateAsync: deleteDashboard } = useMutation(deleteDashboardAction, {
    onSuccess: () => {
      message.success({ content: successDeleteLabel });
      navigate(window.location.pathname);
      queryClient.invalidateQueries([GET_FOUNDER_DASHBOARDS]);
    },
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
    },
  });
  const { modal: confirmDeleteDashboardModal, show: showConfirmDeleteDashboardModal } = useConfirmModal({
    title: deleteDashboardTitleLabel,
    content: getContentOfDeleteModal(),
    onConfirm: () => deleteDashboard(dashboardId),
  });

  const dotMenu = (
    <Menu>
      <S.Item onClick={onChangeNameClick} data-testid="change-name-item">
        <span>{changeNameLabel}</span>
      </S.Item>
      <S.Item onClick={showConfirmDeleteDashboardModal} data-testid="delete-item">
        <span>{deleteLabel}</span>
      </S.Item>
    </Menu>
  );

  return (
    <S.Wrapper data-testid="submenu">
      {confirmDeleteDashboardModal}
      <Visibility hasCloseConnection={hasCloseConnection} visibility={visibility} dashboardId={dashboardId} />
      <S.Buttons>
        <DropdownList
          overlay={dotMenu}
          dropdownInvokerElement={
            <S.DotButton data-testid="founder-dashboards-more-options-button" size="small">
              <S.DotsIcon />
            </S.DotButton>
          }
        />
        <S.AddMetricButton size="small" onClick={displayAddForm} data-testid="add-metric-button">
          <S.PlusIconWrapper>
            <PlusCircleOutlined />
            {addMetricLabel}
          </S.PlusIconWrapper>
        </S.AddMetricButton>
        <ShareDashboard dashboardId={dashboardId} />
      </S.Buttons>
    </S.Wrapper>
  );
};
