import { V2SignOutButton } from '@/components/ui/v2-sign-out-button/v2-sign-out-button';
import { V2Links } from '@/v2-router/const/links';
import { Roles } from '@domain/accounts/roles';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Buttons from '../../parts/buttons/buttons';
import FormHolder from '../../parts/form-holder/form-holder';

import S from './choose-role.form.styles';

export const SESSION_STORAGE_REGISTRATION_KEY = 'registrationData';

const ChooseRoleForm = () => {
  const [selectedRole, setSelectedRole] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const lastSelectedData = sessionStorage.getItem(SESSION_STORAGE_REGISTRATION_KEY);

    if (lastSelectedData) {
      const { role: lastSelectedRole } = JSON.parse(lastSelectedData);
      navigate({ pathname: V2Links.sharedRegisterRole({ role: lastSelectedRole }), search: window.location.search });
    }
  }, []);

  const [iamLabel, advisorLabel, founderLabel, investorLabel] = useTranslation([
    'register.chooseRole.Iam',
    'register.chooseRole.advisor',
    'register.chooseRole.founder',
    'register.chooseRole.investor',
  ]);

  const roleToLabelMap: { [key in Exclude<Roles, Roles.INVESTOR_NED | Roles.NO_ROLE>]: string } = {
    [Roles.NED]: advisorLabel,
    [Roles.FOUNDER]: founderLabel,
    [Roles.INVESTOR]: investorLabel,
  };

  return (
    <>
      <FormHolder data-testid="form-holder">
        {Object.keys(roleToLabelMap).map((role, index) => {
          const isActive = selectedRole === role;
          return (
            <S.ButtonWrapper
              key={`${role}-${index}`}
              data-testid={`select-button-${role}`}
              active={isActive}
              onClick={() => {
                setSelectedRole(role);
              }}
            >
              <S.CheckedIndicator>{isActive && <S.CheckMarkIcon />}</S.CheckedIndicator>
              <S.RoleLabel>
                {`${iamLabel} `}
                {roleToLabelMap[role as Exclude<Roles, Roles.NO_ROLE | Roles.INVESTOR_NED>]}
              </S.RoleLabel>
            </S.ButtonWrapper>
          );
        })}
        <Buttons
          data-testid="continue-button"
          onClick={() => {
            navigate({
              pathname: V2Links.sharedRegisterRole({ role: selectedRole || '' }),
              search: window.location.search,
            });
          }}
          disabled={!selectedRole}
          hideBackButton
        />
      </FormHolder>
      <V2SignOutButton />
    </>
  );
};

export default ChooseRoleForm;
