import styled, { css } from 'styled-components';
import type {
  NotificationIndicatorPlacement,
  NotificationIndicatorPlacementMode,
} from './v2-notification-indicator.types';

const Wrapper = styled.span<{
  $position?: NotificationIndicatorPlacement;
  $small?: boolean;
  $enlargeWithPadding?: boolean;
}>`
  position: relative;

  ${({ $position, $enlargeWithPadding, $small }) => {
    const spacer = $small ? '10px' : '20px';

    if (!$enlargeWithPadding) {
      return css``;
    }

    switch ($position) {
      case 'top-right':
      case 'bottom-right':
      case 'middle-right':
        return css`
          padding-right: ${spacer};
        `;
      case 'top-left':
      case 'bottom-left':
      case 'middle-left':
        return css`
          padding-left: ${spacer};
        `;
      default:
        return css``;
    }
  }}
`;

const V2NotificationIndicator = styled.span<{
  $small?: boolean;
  $position?: NotificationIndicatorPlacement;
  $placementMode?: NotificationIndicatorPlacementMode;
}>`
  width: ${({ $small }) => ($small ? '6px' : '16px')};
  height: ${({ $small }) => ($small ? '6px' : '16px')};
  color: ${({ theme }) => theme.v2.colors.neutral[50]};
  background: ${({ theme }) => theme.v2.colors.magenta[400]};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
  font-size: 10px;
  line-height: 1;
  position: ${({ $position }) => ($position ? 'absolute' : 'relative')};

  ${({ $position, $placementMode }) => {
    switch ($position) {
      case 'top-right':
        return css`
          top: 0;
          right: 0;
          transform: ${$placementMode === 'outside' ? 'translate(75%, -75%)' : 'none'};
        `;
      case 'top-left':
        return css`
          top: 0;
          left: 0;
          transform: ${$placementMode === 'outside' ? 'translate(-75%, -75%)' : 'none'};
        `;
      case 'bottom-right':
        return css`
          bottom: 0;
          right: 0;
          transform: ${$placementMode === 'outside' ? 'translate(75%, 75%)' : 'none'};
        `;
      case 'bottom-left':
        return css`
          bottom: 0;
          left: 0;
          transform: ${$placementMode === 'outside' ? 'translate(-75%, 75%)' : 'none'};
        `;
      case 'middle-right':
        return css`
          top: 50%;
          right: 0;
          transform: translateY(-50%) translateX(${() => ($placementMode === 'outside' ? '75%' : '0')});
        `;
      case 'middle-left':
        return css`
          top: 50%;
          left: 0;
          transform: translateY(-50%) translateX(${() => ($placementMode === 'outside' ? '-75%' : '0')});
        `;
      default:
        return css``;
    }
  }}
`;

export default { V2NotificationIndicator, Wrapper };
