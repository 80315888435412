import { Roles } from '@/domain/accounts/roles';
import { V2Links } from './links';
import { V2Routes } from './routes';
import type { V2LinksCollection } from './types';

export type PermsRedirect = {
  invalidOnboardValue: (typeof V2Links)[keyof V2LinksCollection];
  invalidActiveValue: (typeof V2Links)[keyof V2LinksCollection];
  invalidRoleValue: (typeof V2Links)[keyof V2LinksCollection];
  invalidCorValue: (typeof V2Links)[keyof V2LinksCollection];
  invalidApiPermissionsValue: (typeof V2Links)[keyof V2LinksCollection];
};

type RedirectRouteConfig<T> = { [Role in Roles]: T };
type FullRedirectRouteConfig = RedirectRouteConfig<PermsRedirect>;
type PartialRedirectRouteConfig = RedirectRouteConfig<Partial<PermsRedirect>>;

const baseRedirect: FullRedirectRouteConfig = {
  [Roles.FOUNDER]: {
    invalidOnboardValue: V2Links.error404,
    invalidActiveValue: V2Links.sharedCheckout,
    invalidRoleValue: V2Links.error404,
    invalidCorValue: V2Links.sharedCorSelection,
    invalidApiPermissionsValue: V2Links.error404,
  },
  [Roles.INVESTOR]: {
    invalidOnboardValue: V2Links.onboardingInvestor,
    invalidActiveValue: V2Links.sharedAwaitingApproval,
    invalidRoleValue: V2Links.error404,
    invalidCorValue: V2Links.sharedCorSelection,
    invalidApiPermissionsValue: V2Links.error404,
  },
  [Roles.NED]: {
    invalidOnboardValue: V2Links.error404,
    invalidActiveValue: V2Links.sharedCheckout,
    invalidRoleValue: V2Links.error404,
    invalidCorValue: V2Links.sharedCorSelection,
    invalidApiPermissionsValue: V2Links.error404,
  },
  [Roles.INVESTOR_NED]: {
    invalidOnboardValue: V2Links.error404,
    invalidActiveValue: V2Links.sharedCheckout,
    invalidRoleValue: V2Links.error404,
    invalidCorValue: V2Links.sharedCorSelection,
    invalidApiPermissionsValue: V2Links.error404,
  },
  [Roles.NO_ROLE]: {
    invalidOnboardValue: V2Links.sharedLogin,
    invalidActiveValue: V2Links.sharedLogin,
    invalidRoleValue: V2Links.sharedLogin,
    invalidCorValue: V2Links.sharedLogin,
    invalidApiPermissionsValue: V2Links.sharedLogin,
  },
};

const routeRedirect = (c: Partial<PartialRedirectRouteConfig> = {}): RedirectRouteConfig<PermsRedirect> => {
  return {
    [Roles.FOUNDER]: { ...baseRedirect[Roles.FOUNDER], ...c[Roles.FOUNDER] },
    [Roles.INVESTOR]: { ...baseRedirect[Roles.INVESTOR], ...c[Roles.INVESTOR] },
    [Roles.NED]: { ...baseRedirect[Roles.NED], ...c[Roles.NED] },
    [Roles.INVESTOR_NED]: { ...baseRedirect[Roles.INVESTOR_NED], ...c[Roles.INVESTOR_NED] },
    [Roles.NO_ROLE]: { ...baseRedirect[Roles.NO_ROLE], ...c[Roles.NO_ROLE] },
  };
};

export const redirectConfig: Record<V2Routes, RedirectRouteConfig<PermsRedirect>> = {
  /**********************************************************
   *  SHARED ROUTES
   */
  [V2Routes.SHARED_START]: routeRedirect({
    [Roles.FOUNDER]: { invalidActiveValue: V2Links.founderHomepage },
    [Roles.INVESTOR]: { invalidActiveValue: V2Links.investorHomepage },
    [Roles.NED]: { invalidActiveValue: V2Links.advisorHomepage },
    [Roles.INVESTOR_NED]: { invalidActiveValue: V2Links.investorAdvisorHomepage },
  }),
  [V2Routes.SHARED_LOGIN]: routeRedirect(),
  [V2Routes.SHARED_REGISTER_INVITED_FOUNDER]: routeRedirect(),
  [V2Routes.SHARED_REGISTER_INVITED_INVESTOR]: routeRedirect(),
  [V2Routes.SHARED_REGISTER]: routeRedirect(),
  [V2Routes.SHARED_REGISTER_ROLE]: routeRedirect(),
  [V2Routes.SHARED_REDIRECT_TO_REGISTER]: routeRedirect(),
  [V2Routes.SHARED_EMAIL_UNVERIFIED]: routeRedirect(),
  [V2Routes.SHARED_CHECKOUT]: routeRedirect({
    [Roles.FOUNDER]: { invalidActiveValue: V2Links.founderHomepage },
    [Roles.NED]: { invalidActiveValue: V2Links.advisorHomepage },
    [Roles.INVESTOR]: { invalidRoleValue: V2Links.investorOnboarding },
  }),
  [V2Routes.SHARED_CHECKOUT_SUCCESS]: routeRedirect({
    [Roles.FOUNDER]: { invalidActiveValue: V2Links.sharedCheckout },
    [Roles.NED]: { invalidActiveValue: V2Links.sharedCheckout },
    [Roles.INVESTOR]: { invalidActiveValue: V2Links.sharedCheckout },
  }),
  [V2Routes.SHARED_CHECKOUT_CANCEL]: routeRedirect({
    [Roles.FOUNDER]: { invalidActiveValue: V2Links.sharedCheckout },
    [Roles.NED]: { invalidActiveValue: V2Links.sharedCheckout },
  }),
  [V2Routes.SHARED_PAYMENT_IN_PROGRESS]: routeRedirect({
    [Roles.FOUNDER]: { invalidActiveValue: V2Links.sharedCheckoutSuccess },
    [Roles.NED]: { invalidActiveValue: V2Links.sharedCheckoutSuccess },
    [Roles.INVESTOR]: { invalidActiveValue: V2Links.sharedCheckoutSuccess },
    [Roles.INVESTOR_NED]: { invalidActiveValue: V2Links.sharedCheckoutSuccess },
  }),
  [V2Routes.SHARED_EXTEND_PRODUCTS]: routeRedirect(),
  [V2Routes.SHARED_UPGRADE_PRODUCTS]: routeRedirect(),
  [V2Routes.SHARED_AWAITING_APPROVAL]: routeRedirect({
    [Roles.INVESTOR]: { invalidActiveValue: V2Links.investorHomepage },
  }),
  [V2Routes.SHARED_COR_SELECTION]: routeRedirect({
    [Roles.FOUNDER]: { invalidCorValue: V2Links.founderHomepage },
    [Roles.INVESTOR]: { invalidCorValue: V2Links.investorHomepage },
    [Roles.NED]: { invalidCorValue: V2Links.advisorHomepage },
    [Roles.INVESTOR_NED]: { invalidCorValue: V2Links.investorAdvisorHomepage },
    [Roles.NO_ROLE]: { invalidCorValue: V2Links.sharedLogin },
  }),
  [V2Routes.SHARED_DASHBOARD_REPORT]: routeRedirect(),

  /**********************************************************
   *  ONBOARDING ROUTES
   */
  [V2Routes.ONBOARDING_ROOT]: routeRedirect(),
  [V2Routes.ONBOARDING_FOUNDER_STEP]: routeRedirect(),
  [V2Routes.ONBOARDING_INVESTOR]: routeRedirect(),
  [V2Routes.ONBOARDING_INVESTOR_STEP]: routeRedirect({
    [Roles.INVESTOR]: { invalidOnboardValue: V2Links.onboardingInvestorSummary },
    [Roles.NED]: { invalidOnboardValue: V2Links.onboardingInvestorSummary },
    [Roles.INVESTOR_NED]: { invalidOnboardValue: V2Links.investorAdvisorHomepage },
  }),
  [V2Routes.ONBOARDING_INVESTOR_SUMMARY]: routeRedirect({
    [Roles.FOUNDER]: { invalidOnboardValue: V2Links.error404 },
    [Roles.INVESTOR]: { invalidOnboardValue: V2Links.investorHomepage },
    [Roles.NED]: { invalidOnboardValue: V2Links.advisorHomepage },
    [Roles.INVESTOR_NED]: { invalidOnboardValue: V2Links.investorAdvisorHomepage },
  }),

  /**********************************************************
   * FOUNDER ROUTES
   */
  [V2Routes.FOUNDER_ROOT]: routeRedirect(),
  [V2Routes.FOUNDER_HOMEPAGE]: routeRedirect(),
  [V2Routes.FOUNDER_PROFILE]: routeRedirect(),
  [V2Routes.FOUNDER_INVESTORS]: routeRedirect(),
  [V2Routes.FOUNDER_ADVISORS]: routeRedirect(),
  [V2Routes.FOUNDER_ADVISORS_SEARCH_ROLE]: routeRedirect(),
  [V2Routes.FOUNDER_REPORT]: routeRedirect(),
  [V2Routes.FOUNDER_MESSAGES]: routeRedirect(),
  [V2Routes.FOUNDER_PUBLIC_PROFILE_INVESTOR]: routeRedirect(),
  [V2Routes.FOUNDER_PUBLIC_PROFILE_ADVISOR]: routeRedirect(),
  [V2Routes.FOUNDER_METRICS]: routeRedirect(),
  [V2Routes.FOUNDER_LEGAL]: routeRedirect(),
  [V2Routes.FOUNDER_NOTIFICATIONS]: routeRedirect(),
  [V2Routes.FOUNDER_ROLES]: routeRedirect(),
  [V2Routes.FOUNDER_ROLES_CREATE]: routeRedirect(),
  [V2Routes.FOUNDER_ROLES_PREVIEW]: routeRedirect(),
  [V2Routes.FOUNDER_ROLES_EDIT]: routeRedirect(),
  [V2Routes.FOUNDER_ROLES_APPLICATIONS]: routeRedirect(),
  [V2Routes.FOUNDER_CONFIRM_INVESTMENT]: routeRedirect(),
  [V2Routes.FOUNDER_CONNECTIONS]: routeRedirect(),

  /**********************************************************
   *  INVESTORS ROUTES
   */
  [V2Routes.INVESTOR_ROOT]: routeRedirect({
    [Roles.INVESTOR_NED]: {
      invalidRoleValue: V2Links.investorAdvisorHomepage,
    },
  }),
  [V2Routes.INVESTOR_HOMEPAGE]: routeRedirect({
    [Roles.INVESTOR_NED]: {
      invalidRoleValue: V2Links.investorAdvisorHomepage,
    },
  }),
  [V2Routes.INVESTOR_PROFILE]: routeRedirect({
    [Roles.INVESTOR_NED]: {
      invalidRoleValue: V2Links.investorAdvisorProfile,
    },
  }),
  [V2Routes.INVESTOR_NOTIFICATIONS]: routeRedirect({
    [Roles.INVESTOR_NED]: {
      invalidRoleValue: V2Links.investorAdvisorNotifications,
    },
  }),
  [V2Routes.INVESTOR_MESSAGES]: routeRedirect({
    [Roles.INVESTOR_NED]: {
      invalidRoleValue: V2Links.investorAdvisorMessages,
    },
  }),
  [V2Routes.INVESTOR_LENS]: routeRedirect({
    [Roles.INVESTOR_NED]: {
      invalidRoleValue: V2Links.investorAdvisorLens,
    },
  }),
  [V2Routes.INVESTOR_PUBLIC_PROFILE_FOUNDER]: routeRedirect({
    [Roles.INVESTOR_NED]: {
      invalidRoleValue: V2Links.investorAdvisorPublicProfileFounder,
    },
  }),
  [V2Routes.INVESTOR_PORTFOLIO]: routeRedirect({
    [Roles.INVESTOR_NED]: {
      invalidRoleValue: V2Links.investorAdvisorPortfolio,
    },
  }),
  [V2Routes.INVESTOR_PORTFOLIO_EXPANDED_VIEW]: routeRedirect({
    [Roles.INVESTOR_NED]: {
      invalidRoleValue: V2Links.investorAdvisorPortfolioExpandedView,
    },
  }),
  [V2Routes.INVESTOR_PORTFOLIO_EXPANDED_VIEW_SINGLE]: routeRedirect({
    [Roles.INVESTOR_NED]: {
      invalidRoleValue: V2Links.investorAdvisorPortfolioExpandedViewSingle,
    },
  }),
  [V2Routes.INVESTOR_PORTFOLIO_COMPANY_DETAILS]: routeRedirect({
    [Roles.INVESTOR_NED]: {
      invalidRoleValue: V2Links.investorAdvisorPortfolioCompanyDetails,
    },
  }),
  [V2Routes.INVESTOR_PULSE]: routeRedirect({
    [Roles.INVESTOR_NED]: {
      invalidRoleValue: V2Links.investorAdvisorPulse,
    },
  }),
  [V2Routes.INVESTOR_CONFIRM_INVESTMENT]: routeRedirect({
    [Roles.INVESTOR_NED]: {
      invalidRoleValue: V2Links.investorAdvisorConfirmInvestment,
    },
  }),

  /**********************************************************
   *  ADVISOR ROUTES
   */
  [V2Routes.ADVISOR_ROOT]: routeRedirect({
    [Roles.INVESTOR_NED]: {
      invalidRoleValue: V2Links.investorAdvisorHomepage,
    },
  }),
  [V2Routes.ADVISOR_HOMEPAGE]: routeRedirect({
    [Roles.INVESTOR_NED]: {
      invalidRoleValue: V2Links.investorAdvisorHomepage,
    },
  }),
  [V2Routes.ADVISOR_PROFILE]: routeRedirect({
    [Roles.INVESTOR_NED]: {
      invalidRoleValue: V2Links.investorAdvisorProfile,
    },
  }),
  [V2Routes.ADVISOR_NOTIFICATIONS]: routeRedirect({
    [Roles.INVESTOR_NED]: {
      invalidRoleValue: V2Links.investorAdvisorNotifications,
    },
  }),
  [V2Routes.ADVISOR_MESSAGES]: routeRedirect({
    [Roles.INVESTOR_NED]: {
      invalidRoleValue: V2Links.investorAdvisorMessages,
    },
  }),
  [V2Routes.ADVISOR_ACADEMY]: routeRedirect({
    [Roles.INVESTOR_NED]: {
      invalidRoleValue: V2Links.investorAdvisorAcademy,
    },
  }),
  [V2Routes.ADVISOR_ROLES]: routeRedirect({
    [Roles.INVESTOR_NED]: {
      invalidRoleValue: V2Links.investorAdvisorRoles,
    },
  }),
  [V2Routes.ADVISOR_ROLES_PREVIEW]: routeRedirect({
    [Roles.INVESTOR_NED]: {
      invalidRoleValue: V2Links.investorAdvisorRolesRolePreview,
    },
  }),
  [V2Routes.ADVISOR_LENS]: routeRedirect({
    [Roles.INVESTOR_NED]: {
      invalidRoleValue: V2Links.investorAdvisorLens,
    },
  }),
  [V2Routes.ADVISOR_LEGAL]: routeRedirect({
    [Roles.INVESTOR_NED]: {
      invalidRoleValue: V2Links.investorAdvisorLegal,
    },
  }),
  [V2Routes.ADVISOR_PUBLIC_PROFILE_FOUNDER]: routeRedirect({
    [Roles.INVESTOR_NED]: {
      invalidRoleValue: V2Links.investorAdvisorPublicProfileFounder,
    },
  }),
  [V2Routes.ADVISOR_CONNECTIONS]: routeRedirect({
    [Roles.INVESTOR_NED]: {
      invalidRoleValue: V2Links.investorAdvisorConnections,
    },
  }),

  /**********************************************************
   *  INVESTOR-ADVISOR ROUTES
   */
  [V2Routes.INVESTOR_ADVISOR_ROOT]: routeRedirect(),
  [V2Routes.INVESTOR_ADVISOR_HOMEPAGE]: routeRedirect(),
  [V2Routes.INVESTOR_ADVISOR_PROFILE]: routeRedirect(),
  [V2Routes.INVESTOR_ADVISOR_NOTIFICATIONS]: routeRedirect(),
  [V2Routes.INVESTOR_ADVISOR_MESSAGES]: routeRedirect(),
  [V2Routes.INVESTOR_ADVISOR_CONNECTIONS]: routeRedirect(),
  [V2Routes.INVESTOR_ADVISOR_PORTFOLIO]: routeRedirect(),
  [V2Routes.INVESTOR_ADVISOR_PORTFOLIO_EXPANDED_VIEW]: routeRedirect(),
  [V2Routes.INVESTOR_ADVISOR_PORTFOLIO_EXPANDED_VIEW_SINGLE]: routeRedirect(),
  [V2Routes.INVESTOR_ADVISOR_PORTFOLIO_COMPANY_DETAILS]: routeRedirect(),
  [V2Routes.INVESTOR_ADVISOR_CONFIRM_INVESTMENT]: routeRedirect(),
  [V2Routes.INVESTOR_ADVISOR_PULSE]: routeRedirect(),
  [V2Routes.INVESTOR_ADVISOR_LENS]: routeRedirect(),
  [V2Routes.INVESTOR_ADVISOR_PUBLIC_PROFILE_FOUNDER]: routeRedirect(),
  [V2Routes.INVESTOR_ADVISOR_ROLES]: routeRedirect(),
  [V2Routes.INVESTOR_ADVISOR_ROLES_PREVIEW]: routeRedirect(),
  [V2Routes.INVESTOR_ADVISOR_LEGAL]: routeRedirect(),
  [V2Routes.INVESTOR_ADVISOR_ACADEMY]: routeRedirect(),

  /**********************************************************
   *  ADMIN ROUTES
   */

  [V2Routes.ADMIN_LOGIN_AS_USER]: routeRedirect(),

  /**********************************************************
   *  ERROR ROUTES
   */
  [V2Routes.ERROR_500]: routeRedirect(),
  [V2Routes.ERROR_404]: routeRedirect(),
  [V2Routes.ERROR_403]: routeRedirect(),

  /**********************************************************
   *  ROOT ROUTES
   */
  [V2Routes.ROOT]: routeRedirect(),

  /**********************************************************
   *  REST ROUTES
   */
  [V2Routes.REST]: routeRedirect(),
};
