import { GET_USER_IDENTITY_ACTION, getUserIdentityAction } from '@/context/messaging/api/get-user-identity.actions';
import useUserAccount from '@/utils/hooks/use-user-account/use-user-account';
import { Session } from '@talkjs/react';
import { useQuery } from '@tanstack/react-query';
import { type FC } from 'react';
import type { UnreadConversation } from 'talkjs/all';
import { MessagingContextProvider, useMessagingContext } from './messaging.context';

const TalkJsSessionProvider: FC = ({ children }) => {
  const { setUnread } = useMessagingContext();
  const {
    state: { products, userId },
  } = useUserAccount();

  const { data: userIdentityData } = useQuery(
    [GET_USER_IDENTITY_ACTION, userId, products.length],
    getUserIdentityAction,
    { refetchOnWindowFocus: false, cacheTime: 0, staleTime: 0 },
  );

  if (!userIdentityData?.data || !userId) {
    return <>{children}</>;
  }

  return (
    <Session
      userId={userId}
      appId={userIdentityData.data.appId}
      signature={userIdentityData.data.signature}
      onUnreadsChange={(conversations: UnreadConversation[]) => setUnread(conversations)}
    >
      {children}
    </Session>
  );
};

export const MessagingProvider: FC = ({ children }) => {
  return (
    <MessagingContextProvider>
      <TalkJsSessionProvider>{children}</TalkJsSessionProvider>
    </MessagingContextProvider>
  );
};
