import { useAuth0 } from '@auth0/auth0-react';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { LegacyRoutes } from '@/v2-router/const/routes';
import { useEffect } from 'react';
import S from './redirect-to-register.styles';
import { useNavigate } from 'react-router-dom';

export const RedirectToRegisterPage = () => {
  const { loginWithRedirect, user, isLoading, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated && !user && !isLoading) {
      loginWithRedirect({
        authorizationParams: {
          screen_hint: 'signup',
        },
      });
    } else if (!isLoading) {
      navigate(LegacyRoutes.ROOT, { replace: true });
    }
  }, [isAuthenticated, isLoading]);

  return (
    <S.SpinnerWrapper>
      <FullHeightSpinner />
    </S.SpinnerWrapper>
  );
};
