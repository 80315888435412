export enum V2Routes {
  ROOT = '/',
  /**********************************************************
   *  SHARED ROUTES
   */
  SHARED_START = '/start',
  SHARED_LOGIN = '/login',
  SHARED_REGISTER = '/register',
  SHARED_REGISTER_INVITED_INVESTOR = '/register/investor/invitation',
  SHARED_REGISTER_INVITED_FOUNDER = '/register/founder/invitation',
  SHARED_REGISTER_ROLE = '/register/:role',
  SHARED_REDIRECT_TO_REGISTER = '/redirect-to-register',
  SHARED_EMAIL_UNVERIFIED = '/email-unverified',
  SHARED_CHECKOUT = '/checkout',
  SHARED_CHECKOUT_SUCCESS = '/checkout/success',
  SHARED_CHECKOUT_CANCEL = '/checkout/cancel',
  SHARED_PAYMENT_IN_PROGRESS = '/payment-in-progress',
  SHARED_EXTEND_PRODUCTS = '/extend-products',
  SHARED_UPGRADE_PRODUCTS = '/upgrade-products',
  SHARED_AWAITING_APPROVAL = '/awaiting-approval',
  SHARED_COR_SELECTION = '/country-of-residence',
  SHARED_DASHBOARD_REPORT = '/progress-report/:hash',

  /**********************************************************
   *  ONBOARDING ROUTES
   */
  ONBOARDING_ROOT = '/onboarding',
  ONBOARDING_FOUNDER_STEP = '/onboarding/founder/:path/:step',
  ONBOARDING_INVESTOR = '/onboarding/investor',
  ONBOARDING_INVESTOR_STEP = '/onboarding/investor/:step',
  ONBOARDING_INVESTOR_SUMMARY = '/onboarding/investor/summary',

  /**********************************************************
   *  FOUNDER ROUTES
   */
  FOUNDER_ROOT = '/founder',
  FOUNDER_HOMEPAGE = '/founder/homepage',
  FOUNDER_PROFILE = '/founder/profile',
  FOUNDER_INVESTORS = '/founder/investors/smart-match',
  FOUNDER_ADVISORS = '/founder/advisors',
  FOUNDER_ADVISORS_SEARCH_ROLE = '/founder/advisors/search/:role',
  FOUNDER_REPORT = '/founder/report/:content',
  FOUNDER_MESSAGES = '/founder/messaging',
  FOUNDER_PUBLIC_PROFILE_INVESTOR = '/founder/public-profile/investor/:id',
  FOUNDER_PUBLIC_PROFILE_ADVISOR = '/founder/public-profile/advisor/:id',
  FOUNDER_METRICS = '/founder/metric/manage/:mode',
  FOUNDER_LEGAL = '/founder/legal',
  FOUNDER_NOTIFICATIONS = '/founder/notifications',
  FOUNDER_ROLES = '/founder/roles/:content',
  FOUNDER_ROLES_CREATE = '/founder/create-new-role',
  FOUNDER_ROLES_PREVIEW = '/founder/role-preview/:id',
  FOUNDER_ROLES_APPLICATIONS = '/founder/role-applications/:id',
  FOUNDER_ROLES_EDIT = '/founder/edit-role/:id',
  FOUNDER_CONFIRM_INVESTMENT = '/founder/confirm-investment-claimed-by-investor/:id',
  FOUNDER_CONNECTIONS = '/founder/connections',

  /**********************************************************
   *  INVESTORS ROUTES
   */
  INVESTOR_ROOT = '/investor',
  INVESTOR_HOMEPAGE = '/investor/homepage',
  INVESTOR_PROFILE = '/investor/profile',
  INVESTOR_NOTIFICATIONS = '/investor/notifications',
  INVESTOR_MESSAGES = '/investor/messaging',
  INVESTOR_LENS = '/investor/lens/:content',
  INVESTOR_PULSE = '/investor/pulse/:content',
  INVESTOR_PUBLIC_PROFILE_FOUNDER = '/investor/public-profile/founder/:mode/:id',
  INVESTOR_PORTFOLIO = '/investor/portfolio',
  INVESTOR_PORTFOLIO_EXPANDED_VIEW = '/investor/portfolio/expanded-view',
  INVESTOR_PORTFOLIO_EXPANDED_VIEW_SINGLE = '/investor/portfolio/expanded-view/:id',
  INVESTOR_PORTFOLIO_COMPANY_DETAILS = '/investor/portfolio/company-details/:relationshipId',
  INVESTOR_CONFIRM_INVESTMENT = '/investor/confirm-investment-claimed-by-founder/:id',

  /**********************************************************
   *  ADVISOR ROUTES
   */
  ADVISOR_ROOT = '/advisor',
  ADVISOR_HOMEPAGE = '/advisor/homepage',
  ADVISOR_PROFILE = '/advisor/profile',
  ADVISOR_NOTIFICATIONS = '/advisor/notifications',
  ADVISOR_MESSAGES = '/advisor/messaging',
  ADVISOR_ACADEMY = '/advisor/academy',
  ADVISOR_ROLES = '/advisor/roles/:content',
  ADVISOR_ROLES_PREVIEW = '/advisor/role-preview/:id',
  ADVISOR_LENS = '/advisor/lens/browse',
  ADVISOR_LEGAL = '/advisor/legal',
  ADVISOR_PUBLIC_PROFILE_FOUNDER = '/advisor/public-profile/founder/:mode/:id',
  ADVISOR_CONNECTIONS = '/advisor/connections',

  /**********************************************************
   *  INVESTOR-ADVISOR ROUTES
   */
  INVESTOR_ADVISOR_ROOT = '/investor-advisor',
  INVESTOR_ADVISOR_HOMEPAGE = '/investor-advisor/homepage',
  INVESTOR_ADVISOR_PROFILE = '/investor-advisor/profile/:role',
  INVESTOR_ADVISOR_NOTIFICATIONS = '/investor-advisor/notifications',
  INVESTOR_ADVISOR_MESSAGES = '/investor-advisor/messaging',
  INVESTOR_ADVISOR_CONNECTIONS = '/investor-advisor/connections',
  INVESTOR_ADVISOR_PORTFOLIO = '/investor-advisor/portfolio',
  INVESTOR_ADVISOR_PORTFOLIO_EXPANDED_VIEW = '/investor-advisor/portfolio/expanded-view',
  INVESTOR_ADVISOR_PORTFOLIO_EXPANDED_VIEW_SINGLE = '/investor-advisor/portfolio/expanded-view/:id',
  INVESTOR_ADVISOR_PORTFOLIO_COMPANY_DETAILS = '/investor-advisor/portfolio/company-details/:relationshipId',
  INVESTOR_ADVISOR_CONFIRM_INVESTMENT = '/investor-advisor/confirm-investment-claimed-by-founder/:id',
  INVESTOR_ADVISOR_PULSE = '/investor-advisor/pulse/:content',
  INVESTOR_ADVISOR_LENS = '/investor-advisor/lens/:content',
  INVESTOR_ADVISOR_PUBLIC_PROFILE_FOUNDER = '/investor-advisor/public-profile/founder/:mode/:id',
  INVESTOR_ADVISOR_ROLES = '/investor-advisor/roles/:content',
  INVESTOR_ADVISOR_ROLES_PREVIEW = '/investor-advisor/role-preview/:id',
  INVESTOR_ADVISOR_LEGAL = '/investor-advisor/legal',
  INVESTOR_ADVISOR_ACADEMY = '/investor-advisor/academy',

  /**********************************************************
   *  ADMIN ROUTES
   */
  ADMIN_LOGIN_AS_USER = '/admin/login-as-user',

  /**********************************************************
   *  ERROR ROUTES
   */
  ERROR_403 = '/error/403',
  ERROR_404 = '/error/404',
  ERROR_500 = '/error/500',

  /**********************************************************
   *  CATCH ALL ROUTE
   */
  REST = '*',
}

export enum LegacyRoutes {
  /* HANDLED */ ROOT = '/',
  /* HANDLED */ LEGACY_START_PAGE = '/start',
  /* HANDLED */ LOGIN = '/login',
  /* HANDLED */ REGISTER = '/register',
  /* HANDLED */ CATCH_ALL = '*',
  /* HANDLED */ ONBOARDING_FOUNDER_STEP = '/onboarding-founder/:path/:step',
  /* HANDLED */ CHECKOUT = '/checkout',
  /* HANDLED */ CHECKOUT_SUCCESS = '/checkout/success',
  /* HANDLED */ CHECKOUT_CANCEL = '/checkout/cancel',
  /* HANDLED */ CONNECTIONS = '/connections',
  /* HANDLED */ EXTEND_PRODUCTS = '/extend-products/checkout',
  /* HANDLED */ UPGRADE_PRODUCTS = '/upgrade-products/checkout',
  /* HANDLED */ ADVISORS_SEARCH_ROLE = '/advisors/search/:role',
  /* HANDLED */ EMAIL_UNVERIFIED = '/email-unverified',
  /* HANDLED */ ERROR_404_PAGE = '/error/404',
  /* HANDLED */ ERROR_403_PAGE = '/error/403',
  /* HANDLED */ PULSE = '/pulse/:content',
  /* HANDLED */ MANAGE_METRIC = '/metric/manage/:mode',
  /* HANDLED */ NOTIFICATIONS = '/notifications',
  /* HANDLED */ PROFILE = '/profile',
  /* HANDLED */ LENS = '/lens/:action',
  /* HANDLED */ ADVISORS = '/advisors',
  /* HANDLED */ LEGAL = '/legal',
  /* HANDLED */ PAYMENT_IN_PROGRESS = '/payment-in-progress',
  /* HANDLED */ MESSAGING = '/messaging',
  /* HANDLED */ NED_PUBLIC_PROFILE = '/public-profile/ned/:id',
  /* HANDLED */ INTERNAL_SERVER_ERROR = '/error/500',
  /* HANDLED */ ROLES = '/roles/:content',
  /* HANDLED */ CREATE_NEW_ROLE = '/create-new-role',
  /* HANDLED */ PREVIEW_ROLE = '/role/:id',
  /* HANDLED */ EDIT_ROLE = '/edit-role/:id',
  /* HANDLED */ ONBOARDING_INVESTOR = '/onboarding-investor',
  /* HANDLED */ ONBOARDING_INVESTOR_SUMMARY = '/onboarding-investor/summary',
  /* HANDLED */ INVESTOR_AWAITING_APPROVAL = '/investor-awaiting-approval',
  /* HANDLED */ ACCESS_NED_ACADEMY = '/ned-academy-access',
  /* HANDLED */ COR_SELECTION = '/country-of-residence',
  /* HANDLED */ FOUNDER_PUBLIC_PROFILE = '/public-profile/founder/:mode',
  /* HANDLED */ PORTFOLIO = '/portfolio',
  /* HANDLED */ PORTFOLIO_EXPANDED_VIEW = '/portfolio/expanded-view',
  /* HANDLED */ PORTFOLIO_EXPANDED_VIEW_SINGLE = '/portfolio/expanded-view/:id',
  /* HANDLED */ PORTFOLIO_COMPANY_DETAILS = '/portfolio/company-details/:relationshipId',
  /* HANDLED */ FOUNDER_CONNECTIONS = '/founder/connections',
  /* HANDLED */ ADVISOR_CONNECTIONS = '/advisor/connections',
  /* HANDLED */ INVESTOR_ADVISOR_CONNECTIONS = '/investor-advisor/connections',
  /* HANDLED */ PROGRESS_REPORT = '/progress-report/:hash',
  /* HANDLED */ INVESTOR_PUBLIC_PROFILE = '/public-profile/investor',
  /* HANDLED */ ROLE_APPLICATIONS = '/role/:id/applications',
  /* HANDLED */ MULTI_ROLE_PROFILE = '/profile/:role',
  /* HANDLED */ REGISTER_ROLE = '/register/:role?',
  /* HANDLED */ REDIRECT_TO_REGISTER = '/redirect-to-register', // IS THIS PAGE EVEN NEEDED?
  /* HANDLED */ REGISTER_INVITED_INVESTOR = '/investor/invitation-registration',
  /* HANDLED */ REGISTER_INVITED_FOUNDER = '/founder/invitation-registration',
  /* HANDLED */ FOUNDER_CONFIRM_INVESTMENT = '/confirm-investment-claimed-by-investor/:id',
  /* HANDLED */ INVESTOR_CONFIRM_INVESTMENT = '/confirm-investment-claimed-by-founder/:id',
}
