import { V2Links } from '@/v2-router/const/links';
import { Roles } from '@domain/accounts/roles';
import { SubscriptionProductStatus, SubscriptionProductType } from '@domain/accounts/subscription-products';
import type { OnboardingStatus } from '@domain/accounts/types';
import { tokenStorage } from '@utils/token/token-storage';
import { CurrencyValue } from '@utils/type-definitions/currencies';
import { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import { AccountStatus, type UserAccountState } from './user-account.context';

export const LOGOUT_LS_KEY = 'logout';

export const handleLogoutStorageEvent = (event: StorageEvent) => {
  if (event.key !== LOGOUT_LS_KEY) return;

  tokenStorage.setAccessToken('', 0);
  window.location.href = V2Links.sharedLogin();
};

export const baseExampleUser: UserAccountState = {
  initialized: true,
  userId: '1',
  isAuthorized: true,
  isLoggingOut: false,
  isLoggingIn: false,
  userRole: Roles.NO_ROLE,
  status: AccountStatus.ACTIVE,
  name: 'Some name',
  email: 'some@gmail.com',
  businessName: 'Some business name',
  avatarUrl: null,
  currency: CurrencyValue.GBP,
  countryOfResidence: CountryCodes.GB,
  products: [],
  permissions: [],
  integrations: {
    codat: { isConnected: false, isFirstSyncCompleted: false },
    crm: { id: null },
    stripe: { customerId: 'cus_P0abcdefghijkl' },
  },
  auth0Provider: null,
  onboarding: {
    founder: 'NotApplicable' as OnboardingStatus,
    founderQualifyingQuestions: 'NotApplicable' as OnboardingStatus,
    investor: 'NotApplicable' as OnboardingStatus,
    ned: 'NotApplicable' as OnboardingStatus,
  },
  group: null,
};

export const founderPlusExampleUser: UserAccountState = {
  ...baseExampleUser,
  userRole: Roles.FOUNDER,
  products: [
    {
      type: SubscriptionProductType.FOUNDER,
      status: SubscriptionProductStatus.APPROVED,
      discoverable: true,
    },
    {
      type: SubscriptionProductType.FOUNDER_RAISE,
      status: SubscriptionProductStatus.APPROVED,
      discoverable: true,
    },
    {
      type: SubscriptionProductType.FOUNDER_PLUS,
      status: SubscriptionProductStatus.APPROVED,
      discoverable: true,
    },
  ],
};

export const founderFreemiumExampleUser: UserAccountState = {
  ...baseExampleUser,
  userRole: Roles.FOUNDER,
  products: [
    {
      type: SubscriptionProductType.FOUNDER,
      status: SubscriptionProductStatus.APPROVED,
      discoverable: true,
    },
  ],
};

export const investorExampleUser: UserAccountState = {
  ...baseExampleUser,
  userRole: Roles.INVESTOR,
  products: [
    {
      type: SubscriptionProductType.INVESTOR,
      status: SubscriptionProductStatus.APPROVED,
      discoverable: true,
    },
  ],
};

export const nedExampleUser: UserAccountState = {
  ...baseExampleUser,
  userRole: Roles.NED,
  products: [
    {
      type: SubscriptionProductType.NED,
      status: SubscriptionProductStatus.APPROVED,
      discoverable: true,
    },
  ],
};
